/* below 1344 px smaller desktop */

@media (max-width: 84rem) {
  .hero {
    max-width: 120rem;
  }

  .heading-primary {
    font-size: 4.4rem;
  }

  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* below 1200 px landscape tablet*/
@media (max-width: 75rem) {
  html {
    font-size: 56.26%;
  }

  .grid-gap {
    column-gap: 4.8rem;
    row-gap: 6.4rem;
  }

  .heading-secondary {
    font-size: 3.6rem;
  }

  .heading-tertiary {
    font-size: 2.4rem;
  }

  .header {
    padding: 0 3.2rem;
  }

  .map-nav-list {
    gap: 3.2rem;
  }

  .hero {
    gap: 4.8rem;
  }

  .testimonials-container {
    padding: 9.6rem 3.2rem;
  }
}

/* below 940 px tablets*/
@media (max-width: 59em) {
  html {
    font-size: 50%;
  }

  .hero {
    grid-template-columns: 1fr;
    padding: 0 8rem;
    gap: 6.4rem;
  }

  .hero-text-box,
  .hero-img-box {
    text-align: center;
  }

  .hero-img {
    width: 60%;
  }

  .delivered-meals {
    justify-content: center;
    margin-top: 3.2rem;
  }

  .logos img {
    height: 2.4rem;
  }

  .step-number {
    font-size: 7.4rem;
  }

  .meal-content {
    padding: 2.4rem 3.2rem 3.2rem 3.2rem;
  }

  .section-testimonials {
    grid-template-columns: 1fr;
  }

  .gallery {
    grid-template-columns: repeat(6, 1fr);
  }

  .cta {
    grid-template-columns: 3fr 2fr;
  }

  .cta-form {
    grid-template-columns: 1fr;
  }

  .btn--form {
    margin-top: 1.2rem;
  }

  .btn-mobile-nav {
    display: block;
  }

  .main-nav {
    background-color: rgb(255, 255, 255, 0.6);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform: translateX(100%);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in-out;

    opacity: 0;

    pointer-events: none;
    visibility: hidden;
  }

  .nav-open .main-nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
  }

  .nav-open .icon-mobile-nav[name="close-outline"] {
    display: block;
  }

  .nav-open .icon-mobile-nav[name="menu-outline"] {
    display: none;
  }

  .main-nav-list {
    flex-direction: column;
    gap: 4.8rem;
  }

  .main-nav-link:link,
  .main-nav-link:visited {
    font-size: 3rem;
  }
}

/* below 704 px smaller tablets*/
@media (max-width: 44em) {
  .container {
    width: 80rem;
    margin: 0 auto;
    padding: 0 3.2rem;
  }

  .grid--3-cols,
  .grid--4-cols {
    grid-template-columns: repeat(2, 1fr);
  }

  .diets {
    grid-column: 1 / -1;
    justify-self: center;
  }

  .heading-secondary {
    margin-bottom: 4.8rem;
  }

  .pricing-plan {
    width: 100%;
  }

  .nav-col {
    grid-row: 1;
    grid-column: span 2;
    margin-bottom: 3.2rem;
  }

  .grid--footer {
    grid-template-columns: repeat(6, 1fr);
  }

  .logo-col,
  .address-col {
    grid-column: span 3;
  }
}

/* below 544 px for phones*/
@media (max-width: 34em) {
  .container {
    width: 54rem;
    margin: 0 auto;
    padding: 0 3.2rem;
  }

  .grid {
    row-gap: 4.8rem;
  }
  .grid--2-cols,
  .grid--3-cols,
  .grid--4-cols {
    grid-template-columns: 1fr;
  }

  .btn,
  .btn:link,
  .btn:visited {
    padding: 2.4rem 1.6rem;
  }

  .section-hero {
    padding: 2.4rem 0 6.4rem 0;
  }

  .hero {
    padding: 0 3.2rem;
  }

  .hero-img {
    width: 80%;
  }

  .logos img {
    height: 1.2rem;
  }

  .step-img-box:nth-child(2) {
    grid-row: 1;
  }

  .step-img-box:nth-child(6) {
    grid-row: 5;
  }

  .step-img-box {
    transform: translateY(24px);
  }

  .testimonials {
    grid-template-columns: 1fr;
  }

  .gallery {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.2rem;
  }

  .cta {
    grid-template-columns: 1fr;
  }

  .cta-text-box {
    padding: 3.2rem;
  }

  .cta-img-box {
    height: 32rem;
    grid-row: 1;
  }
}

/* FONT SIZE SYSTEM (px) 10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98 - FONT WEIGHTS: Default: 400 medium: 500 semi-bold: 600 bold: 700 - LINE HEIGHTS Default: 1 Small: 1.05 Paragraph : 1.6 SPACING SYSTEM (px) 2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128 from 8 up to 24 for components inside for bigger one's go up to 32 for inside COLOR PRIMARY COLOR : #e67e22 Tints: #fdf2e9 Shades:#cf711f Accents: Greys: #555 #333 -- 05 SHADOWS -- 06 BORDER-RADIUS -- 07 WHITESPACE */ ;
